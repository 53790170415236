import React from 'react';
import SubmitHandler from './HandleSubmit';  // Ensure the path is correct based on your project structure

const PopupPanel = ({
  isPanelVisible,
  Name,
  ID, 
  PhoneNumber,
  TGLink,
  InstagramLink,
  setName,
  setID, 
  setPhoneNumber,
  setTGLink,
  setInstagramLink,
  togglePanel, 
  setViewUrl, 
  setEditUrl
}) => {
  return (
    <>
      {isPanelVisible && (
        <div className="popup-panel">
          <h2>Input Panel</h2>
          <SubmitHandler
            Name={Name}
            ID={ID} 
            PhoneNumber={PhoneNumber}
            TGLink={TGLink}
            InstagramLink={InstagramLink} 
            setName={setName}
            setID={setID} 
            setPhoneNumber={setPhoneNumber}
            setTGLink={setTGLink}
            setInstagramLink={setInstagramLink}
            togglePanel={togglePanel} 
            setViewUrl={setViewUrl} 
            setEditUrl={setEditUrl} 
          />
        </div>
      )}
    </>
  );
};

export default PopupPanel;