import React from 'react';
import styles from './HomePage.css';
import logo from './moneo logo.svg';
import background from './mockup.png'; // Add your background image here
import nfcIcon from './icons/contactless-icon.svg';
import pencilIcon from './icons/pencil-icon.svg';
import contactCardIcon from './icons/business-card-icon.svg';

import paperplaneIcon from './icons/blue-paperplane.svg';
import palleteIcon from './icons/blue-pallete.svg';
import networkIcon from './icons/blue-network.svg';

function HomePage() {
  return (
    <div className="landing-page">
      <div className="upper-section" style={{ backgroundImage: `url(${background})` }}>
        <Header />
        <main>
        <h1 className='title'> کارت الکترونیکی <span className='moneoTitle'>مونئو</span></h1>
          <p className='paragraph1'>با مونوکارت خودتو بهتر معرفی کن</p>
          <button className="custom-button" >کارتتو بگیر</button>
          <p className='paragraph1'>مورد اعتماد +500 کاربر در سراسر کشور</p>
        </main>
        <div className="tall-section"></div>
        {/*<div className='descriptions-container'>
            <div className='description-box'> <img src={nfcIcon} style={{height:'50px', width:'auto'}}></img></div>
            <div className='description-box'> <img src={pencilIcon} style={{height:'50px', width:'auto'}}></img></div>
            <div className='description-box'> <img src={contactCardIcon} style={{height:'50px', width:'auto'}}></img></div>
        </div>
        <p className="black-text">قابلیت شخصی سازی کارت ها</p>
        <p className="black-text" style={{left:'35%'}}>انتقال اطلاعات فقط با نزدیک کردن کار ت</p>
        <p className="black-text" style={{left:'65%'}}>امکان ویرایش اطلاعات بعد از خرید</p>*/}
      </div>

      {/* <div class="feature-container">
        <div className='feature-box' style={{top:'0%'}}>
          <img src={paperplaneIcon} style={{height:'50px', width:'auto'}}></img>
          <img src={networkIcon} style={{height:'50px', width:'auto'}}></img>
          <img src={palleteIcon} style={{height:'50px', width:'auto'}}></img>
        </div> 

        <div className='feature-box' style={{top:'5%'}}>
          <img src={paperplaneIcon} style={{height:'50px', width:'auto'}}></img>
          <img src={networkIcon} style={{height:'50px', width:'auto'}}></img>
          <img src={palleteIcon} style={{height:'50px', width:'auto'}}></img>
        </div> 
      </div> */}
        
        
        

        <h1 className='objection1'>
          مونوکارت روی همه گوشی ها جواب میده؟
        </h1>
        <p className='objection1'>
        بله روی همه گوشی ها جواب میده، حتی گوشی های قدیمی که قابلیت ان اف سی ندارن میتونن با دوربین گوشی QR-Code پشت کارت رو اسکن کنند.
        </p >

        <div className="tall-section"></div>
    </div>
  );
}

function Header() {
  return (
    <div className="header-container">
    <header>
      <img src={logo} alt="Logo" className="logo" />
      <nav>
        <ul style={{ textAlign: 'right' }}>
          <li className='header-buttons'><a href="#">ثبت سفارش</a></li>
          <li className='header-buttons'><a href="./loginpage">ورود</a></li>
        </ul>
      </nav>
    </header>
    </div>
  );
}

function UpperSection() {
  return (
    <div className="upper-section">
      
    </div>
  );
}

export default HomePage;