import React from 'react';
import './ContactInfoPage.css';
import ContactSection from './ContactSection';
import logo from './logo.png';

function ContactInfoPage ({contactData}) {

    const handleAddContact = () => {
        // Create vCard string
        const vCardData = `
        BEGIN:VCARD
        VERSION:3.0
        FN:${contactData.name}
        TEL;TYPE=CELL:${contactData.phone}
        EMAIL:${contactData.email}
        END:VCARD
            `;
        
            // Create Blob from vCard data
            const blob = new Blob([vCardData], { type: 'text/vcard' });
        
            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);
        
            // Create a temporary anchor element to download the vCard
            const link = document.createElement('a');
            link.href = url;
            link.download = `${contactData.name}.vcf`; // Filename for the vCard
            document.body.appendChild(link);
        link.click();
    
        // Clean up and revoke the Blob URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      };


  return (
    <div className="gradient-page">
      <h1 className="name">{contactData.name}</h1>
      <p className="bio">be special!</p>
      <img src={logo} 
      alt="Logo" 
      className='logo-contact-info' ></img>
      <div className='vertical-container'>
        {contactData.phone && <button className="wide-button" style={{top:'42%'}} onClick={() => window.location.href = `tel:${contactData.phone}`}>شماره تماس:</button>}
        {contactData.instagram && <button className="wide-button" style={{top:'68%'}} onClick={() => window.location.href = `https://instagram.com/ ${contactData.instagram}`}>پیج اینستاگرام</button>}
        {contactData.telegram &&  <button className="wide-button" style={{top:'81%'}} onClick={() => window.location.href = `https://t.me/${contactData.telegram}`}>تلگرام</button>}
      </div>
        
    </div>
  );
}

export default ContactInfoPage;