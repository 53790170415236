import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContactInfoPage from './ContactInfoPage';

const UrlHandler = () => {
  const { url } = useParams(); // Get the URL from the route parameters
  console.log(url);
  const [Name, setName] = useState(null);
  const [PhoneNumber, setPhoneNumber] = useState(null);
  const [TGLink, setTGLink] = useState(null);
  const [InstagramLink, setInstagramLink] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const checkUrl = async () => {
      try {
        const response = await fetch(`https://moneo.ir/api/${url}`);
        if (response.ok) {
          const data = await response.json();
          setPhoneNumber(data.PhoneNumber);
          setName(data.Name);
          setTGLink(data.TGLink);
          setInstagramLink(data.InstagramLink);
        } else if (response.status === 404) {
          setError("Page not found");
        } else {
          setError('An error occurred');

        }
      } catch (err) {
        setError('An error occurred');
      }
    };

    checkUrl();
  }, [url]);

  if (error) {
    return <div>{error}</div>;
  }

  if (PhoneNumber) {
    const contactData = {
      name: Name,
      phone: PhoneNumber, // Could be null
      instagram: InstagramLink, // Could be null
      telegram: TGLink, // Could be null
    };
      
    return (
      <ContactInfoPage contactData={contactData} />
        // Add other contact methods as needed
    )
  }
  /*<a href={"instagram://user?username="+InstagramLink} style={{ textDecoration: 'none' }}>
          <FaInstagram style={{ fontSize: '36px', border: '2px solid #3AFF3A', borderRadius: '50%', padding: '10px', color: '#3AFF3A' }} />*/
  return <div>Loading...</div>;
};

export default UrlHandler;