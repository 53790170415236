import React from 'react';
import styles from './LoginPage.css';
import googleSignInLogo from '../../Assets/sign-in-google-icon.svg';

const RightSection = () => {
  return <div className="right-section" />;
};

const MiddleRectangle = () => {
  return (
    <div className="middle-rectangle">
      <div className="logintitle">ورود به سایت</div>
      <div className="phonenumbertext-login">:شماره تلفن</div>
      <div className="textbox-wrapper">
  <input type="text" className="textbox" />
</div>  
      <button className="button">مرحله بعد</button>
      <div className="centered-text">یا</div>
      <img src={googleSignInLogo} alt="Google Logo" className="google-logo" />
      <div className="bottom-text">ورود با گوگل</div>
    </div>
  );
};

const LoginPage = () => {
  return (
    <div>
      <RightSection />
      <MiddleRectangle />
    </div>
  );
};

export default LoginPage;