import React from 'react';       // Adjust the path as needed
import './App.css';
import UrlHandler from './Components/URLHandler';
import AdminDashboard from './Components/AdminDashboard';
import HomePage from './Components/homepage/HomePage.js';
import LoginPage from './Components/loginpage/LoginPage.js';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes} from 'react-router-dom';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<HomePage/>} /> {/* Ensure this path matches */}
      <Route path="/loginpage" element={<LoginPage />} /> {/* Ensure this path matches */}
      <Route path="/admin" element={<AdminDashboard />} /> {/* Ensure this path matches */}
      <Route path="/:url" element={<UrlHandler />} /> {/* Ensure this path matches */}
      </Routes>
    </Router>
  );
};

export default App;