import React from 'react';

const SubmitHandler = ({ ID, PhoneNumber, setID, setPhoneNumber, togglePanel, setViewUrl, setEditUrl, Name, setName, TGLink, setTGLink, InstagramLink, setInstagramLink }) => {
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    const edit = generateRandomString(18);
    
    // If ID is empty, generate a random string
    const newID = ID || generateRandomString(9);
    
    const data = {Name, newID, PhoneNumber, edit , TGLink, InstagramLink};

    try {
      const response = await fetch('https://moneo.ir/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Data submitted successfully!');
        const newViewUrl = `https://moneo.ir/${newID}`;
        const newEditUrl = `${newViewUrl}/${edit}`;

        setViewUrl(newViewUrl);
        setEditUrl(newEditUrl);
        
        console.log('Normal URL: ', newViewUrl);
        console.log('Edit URL: ', newEditUrl);

        // Optionally reset input fields
        setID('');
        setPhoneNumber('');
        setName('');
        setTGLink('');
        setInstagramLink('');
        togglePanel(); // Close the panel after submission
      } else {
        console.error('Error submitting data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input 
        type="text" 
        placeholder="Name" 
        value={Name} 
        onChange={(e) => setName(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="ID" 
        value={ID} 
        onChange={(e) => setID(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="PhoneNumber" 
        value={PhoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="Telegram ID" 
        value={TGLink} 
        onChange={(e) => setTGLink(e.target.value)} 
      />
      <input 
        type="text" 
        placeholder="Instagram ID" 
        value={InstagramLink} 
        onChange={(e) => setInstagramLink(e.target.value)} 
      />
      <button type="submit">Submit</button>
      <button type="button" onClick={togglePanel}>Close</button>
    </form>
  );
};

export default SubmitHandler;